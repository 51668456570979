<template>
    <div class="clientcabin">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="pt-6">
                    <p class="headline text-center mb-8">
                        Watch this video to know what happens next
                    </p>
                    <external-video-embed
                        video="https://fast.wistia.com/embed/iframe/97t78q06c4"
                        class="with-shadow mb-10"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ExternalVideoEmbed } from '@/components/ExternalVideoEmbed';

@Component({
    components: {
        ExternalVideoEmbed
    }
})
export default class OnBoarding extends Vue {}
</script>

<style lang="scss" scoped>
.clientcabin::v-deep {
    color: $black;
    background-color: $white;
    height: 100%;

    .with-shadow {
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);
    }
}
</style>
